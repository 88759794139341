import $ from 'jquery';
import Inputmask from 'inputmask';

$(function(){
    "use strict";
    let $b = $('body');
    let $popup = $b.find('.popup-form');
    let responseInt;
    let type;

    if ( !$popup.length ) return;

    function init() {
        Inputmask("email").mask($popup.find('.-email'));
        Inputmask("+7 (999) 999-99-99").mask($popup.find('.-phone'));
        Inputmask({
            'alias': 'datetime',
            'inputFormat': 'mm/dd/yyyy'
        }).mask($popup.find('.-date'));
        /*
        Inputmask({
            'alias': 'datetime',
            'inputFormat': "hh:mm TT"
        }).mask($popup.find('.-time'));
        */
    }

    function handleOpener(url, typeResrvation) {
        $b.addClass('-open-popup-form');
        $b.removeClass('-open-menu--mob');
        type = typeResrvation;
        $popup.css('top', $(document).scrollTop())
        $popup.find('.pf-cont')
            .fadeOut(0)

        $.post(url)
            .done(r=>{
                $popup.find('.pf-cont')
                    .html(r)
                    .fadeIn(300)
                init();
            }).fail(r=>{
            $b.removeClass('-open-popup-form');
        });
    }

    $b.on('click','[href="#reservation-order"]', function(e){
        e.preventDefault();
        handleOpener('/site/form-order', $(this).attr('data-type-reservation'));
    });

    $b.on('click','[href="#reservation-trip"]', function(e){
        e.preventDefault();
        handleOpener('/site/form-trip', $(this).attr('data-type-reservation'));
    });

    $b.on('click','[href="#callback"]', function(e){
        e.preventDefault();
        handleOpener('/site/form-call', $(this).attr('data-type-reservation'));
    });

    $b.on('click','.pf-close, .pf-overlay', function(e){
        e.preventDefault();
        $b.removeClass('-open-popup-form');
    });

    $popup.on('submit', 'form', function(e) {
        e.preventDefault();

        let $f = $(this);
        let errors = false;

        $popup.removeClass('-load');

        $f.find('[data-empty]').each(function(){
            let t = $(this);
            if ( t.attr('data-empty') && t.val() === '' ) {
                t.parent().addClass('-error');
                t.prev().text(t.attr('data-empty'));
                errors = true;
            }
        });

        if (!errors) {
            $popup.addClass('-load');
            $popup.find('[type="hidden"]').val(type);

            $.post('/site/contact-form', $f.serialize())
                .done(function(response){
                    let res = response;
                    if (res.status) {
                        $popup.removeClass('-load');
                        $popup.addClass('-success');
                    } else {
                        $popup.removeClass('-load');
                        $popup.addClass('-error');
                    }
                })
                .fail(function(r){
                    $popup.removeClass('-load');
                    $popup.addClass('-error');
                });

            $f[0].reset();
            $popup.find('[type="hidden"]').val(type);

            clearInterval(responseInt);
            responseInt = setTimeout(function(){
                $popup.removeClass('-success');
                $popup.removeClass('-error').trigger('click');
                $b.find('.pf-close')
            }, 4000);
        }

        return false;
    });

    $popup.on('keydown', '.pf__input input, .pf__input textarea', function(){
        let t = $(this);
        t.parent().removeClass('-error');
    });

    $popup.on('blur', '.pf__input input, .pf__input textarea', function(){
        let t = $(this);

        if (t.val() === '') {
            t.prev('span').text('');
            t.parent().removeClass('-error');
        }

    });

});

$(function(){
    $('body').on('click','[href="#toreservation"]', function(e) {
        e.preventDefault();
        $('html, body').animate({'scrollTop': $('#view').offset().top}, 400)
    });
})