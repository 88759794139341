import $ from 'jquery';
import 'slick-carousel';

$(function(){
    "use strict";
    let $b = $('body');

    if ( !$b.find('.photos-capsules').length ) return;

    $b.on('click','.pc-galleries__photo', function(){
        let t = $(this);

        t.siblings().removeClass('-act');
        t.addClass('-act');

        $b.find('.photos-capsules .-show').removeClass('-show');
        $b.find('.photos-capsules .pc-item').eq( $b.find('.photos-capsules .pc-galleries__photo').index(t) ).addClass('-show');

        initSlick();
    });

    function initSlick() {
        $b.find('.pc-item__photos').each(function() {
            let t = $(this);

            t.hasClass('slick-initialized') ?
                t.slick('unslick') : null;

                t.slick({
                    dots: false,
                    arrows: true,
                    prevArrow: t.parent().find('.pc-item__nav__prev'),
                    nextArrow: t.parent().find('.pc-item__nav__next'),
                    infinite: true
                });
        });
    }

    $(window).on('resize', initSlick);
    initSlick();

});
