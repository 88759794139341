import $ from 'jquery';

//lang
$(function(){
    "use strict";
    let $b = $('body');

    let langMenuSel = '.top-menu__lang';
    let $langMenu = $b.find(langMenuSel);
    $b.on('click.-close-lang', function(e){
        if ( !$b.find(e.target).hasClass('top-menu__lang__selected') )
            $langMenu.removeClass('-open-lang');
    });
    $b.on('click', langMenuSel + ' > span', function(e){
        e.preventDefault();
        $langMenu.addClass('-open-lang');
    });

});

//section coord
$(function() {
    "use strict";

    let $w = $(window);
    let $b = $('body');

    if ( !$b.find('.top').length ) return;

    function getCoord() {

        let result = {};

        [
            '#banner',
            '#trip',
            '#view',
            '#about',
            '#gallery',
            '#contact'
        ].map(function(t, i) {
                let $s = $b.find(t);

                if ( $s )
                    result[t] = {
                        'name': t.replace('#',''),
                        'offsetTop': $s.offset().top,
                        'outerHeight': $s.outerHeight(true),
                        'offsetBot': $s.offset().top + $s.innerHeight(),
                        'mTop': parseFloat($s.css('margin-top')),
                        'index': i
                    };

            });

        return result;
    }

    $.coord = getCoord();
    $w.on('resize.coord', function(){
        $.coord = getCoord();
    });

});

//scroll
$(function() {
    "use strict";

    const SCROLL_SPEED = 600;

    let $w = $(window);
    let $b = $('body');

    let f_isClick = false;

    //hide menu
    $b.find('.top-menu-list a:not([href="#callback"])').each(function(){
        if ( !$b.find($(this).attr('href')).length )
            $(this).hide();
    });

    function topMenuEvent() {

        $b.on('click', '.top-menu-list a:not([href="#callback"])', function(e) {

            e.preventDefault();
            $b.removeClass('-open-menu--mob');
            f_isClick = true;

            let t = $(this);
            let t_offset = $b.find( t.attr('href') ).offset().top - $b.find('.top').innerHeight();

            let $act = t.parent();

            if ($act.length) {
                $b.find('.tml-line').css({
                    'left': $act.position().left,
                    'width': $act.width()
                });
            }

            $('html, body').stop(true).animate({'scrollTop': t_offset },
                SCROLL_SPEED * ( Math.abs( $w.scrollTop() - t_offset ) / $w.height() ), function(){
                    f_isClick = false;
                });

            t.parent().parent().find('.-act').removeClass('-act');
            t.addClass('-act');
        });

        $b.on('click', '[href="#top"]', function(e) {
            e.preventDefault();
            f_isClick = true;
            $('html, body').stop(true).animate({'scrollTop': 0 }, SCROLL_SPEED * ( $w.scrollTop() / $w.height() ), function(){
                f_isClick = false;
            });
            $b.find('.tml-line').css({'left': 0,'width': 0});
        });

    }

    $w.on('scroll.top-menu', function(){

        let st = $(this).scrollTop();

        for ( let name in $.coord ) {

            let coord = $.coord[name];

            //animateSection
            if ( st >= coord.offsetTop - $w.height() )
                $b.addClass( '-animated-' + coord.name );

            else if ( $b.height() - $w.height() - st < 100 )
                $b.addClass('-animated-contact');
            //end animateSection

            //top-menu
            if (!f_isClick) {
                if (
                    st >= coord.offsetTop - coord.mTop - $b.find('.top').innerHeight() - 10 &&
                    st <= coord.offsetBot - coord.mTop - $b.find('.top').innerHeight() - 10
                ) {
                    let $act = $b.find('.top-menu-list [href="#' + coord.name + '"]').parent();
                    $b.find('.top-menu-list .-act').removeClass('-act');
                    $act.children().addClass('-act');

                    if ($act.length) {
                        $b.find('.tml-line').css({
                            'left': $act.position().left,
                            'width': $act.width()
                        });
                    }
                } if (name == '#banner') {
                    $b.find('.top-menu-list .-act').removeClass('-act');
                    $b.find('.tml-line').css({'left': 0,'width': 0});
                }
            }
            //end top-menu

        }

    });

    topMenuEvent();
    $w.trigger('scroll.top-menu');
    $w.on('resize',function(){
        $w.trigger('scroll.top-menu');
    });

});

//menu tab, mob
$(function() {
    "use strict";

    let $w = $(window);
    let $b = $('body');

    $b.on('click', '.top__sandwich', function(e) {
        e.preventDefault();
        $b.toggleClass('-open-menu--mob');
    });

    $w.on('resize.-menu-device', function(){
        if ( $w.width() >= 992 )
            $b.removeClass('-open-menu--mob');
    });

});
