import $ from 'jquery';

$(function(){
    "use strict";
    $(document).find('body').append( $('template#yandex-map').html() );
    window.onYampsReady = () => {

        let $b = $('body');
        let $w = $(window);

        if ( !$b.find('#map').length ) return;

        //init map
        let myMap;
        let initZoom = 14;
        let $map = $b.find('#map');

        function getCoords() {

            let res = [43.115536, 131.885485];

            if ($w.width() < 768 && $map.attr('data-lat-mob') && $map.attr('data-lng-mob'))
                res = [$map.attr('data-lat-mob'), $map.attr('data-lng-mob')];
            else if ($w.width() < 992 && $map.attr('data-lat-pad') && $map.attr('data-lng-pad'))
                res = [$map.attr('data-lat-pad'), $map.attr('data-lng-pad')];
            else if ($map.attr('data-lat-mob') && $map.attr('data-lng-mob'))
                res = [$map.attr('data-lat-mob'), $map.attr('data-lng-mob')];

            return res;

        }

        function init() {
            let coords = getCoords();

            myMap = new ymaps.Map("map", {
                center: coords,
                zoom: initZoom,
                controls: []
            });

            let myPlacemark = new ymaps.Placemark(coords, {}, {
                iconLayout: 'default#image',
                iconImageHref: $map.attr('data-path-map-point'),
                iconImageSize: [36, 46],
                iconImageOffset: [0, 0]
            });

            myMap.geoObjects
                .add(myPlacemark);
        }


        try {

            $b.on('click', '.maps-desc-box', function (e) {
                e.preventDefault();
                $b.toggleClass('-open-contact-card');
            });

            $b.on('click', '.maps-desc__card__map', function (e) {
                e.preventDefault();
                myMap.setCenter(getCoords(), initZoom);
                return false;
            });

            ymaps.ready(init);

            $w.on('resize', function () {
                $b.find('.maps-desc__card__map').trigger('click');
            });

        } catch(e) { console.warn("maps error:", e) }
    }
});