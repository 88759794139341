import $ from 'jquery';

$(function(){
    "use strict";
    let $b = $('body');

    if ( !$b.find('.tabs').length ) return;

    $b.on('click','.tabs__nav a', function(e){
        e.preventDefault();

        let t = $(this);
        let href = t.attr('href');
        let $tabs = t.closest('.tabs');

        $tabs.find('.-act,.-show')
            .removeClass('-act')
            .removeClass('-show');

        t.parent().addClass('-act');
        $tabs.find(href).addClass('-show');

    });

});
