import $ from 'jquery';
import 'slick-carousel';

$(function(){
    "use strict";
    let $b = $('body');

    if ( !$b.find('.trip').length ) return;

    $b.find('.trip-carousel').slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1162,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    });
    $b.find('.trip-carousel').removeClass('-hid');

});
