import $ from 'jquery';

$(function(){
    "use strict";
    let $b = $('body');
    let $popup = $b.find('.popup-text');

    if ( !$popup.length ) return;

    $b.on('click','[data-popup-text]', function(e){
        e.preventDefault();
        $b.addClass('-open-popup-text');
        $popup.addClass('-load');

        $.post('/page/' + $(this).attr('href').replace('#',''))
            .done(function(response){

                $popup.find('.pt-cont').html(response);
                $popup.removeClass('-load');
                $popup.addClass('-loaded');

            })
            .fail(function(){
                $popup.removeClass('-load');
                $b.removeClass('-open-popup-text');
            });
    });

    $b.on('click','.pt-close, .pt-overlay', function(e){
        e.preventDefault();
        $b.removeClass('-open-popup-text');
        $popup.removeClass('-load');
        $popup.removeClass('-loaded');
    });

});
