import $ from "jquery";

const points = {
    'm': 0,
    't': 768,
    'd': 992
};

$(function(){

    let $b = $('body');
    const pointsRange = {
        'm': [points.m, points.t],
        't': [points.t, points.d],
        'd': [points.d, 100000],
    };

    function getCss(className, size, img) {
        return [
            `@media (min-width:${pointsRange[size][0]}px) and (max-width:${pointsRange[size][1]}px ) {`,
                `.${className} {background-image:url(${img}) !important}`,
            `}`
        ].join('')
    }

    function loadImg() {
        let imageCss = '';

        $b.find('.responseImg').each(function(i) {
            let t = $(this);
            const uniqueId = '__' + Math.random().toString(36).substr(2, 9);
            t.addClass(uniqueId);
            if (t.data('m')) imageCss += getCss(uniqueId,'m', t.data('m'))
            if (t.data('t')) imageCss += getCss(uniqueId, 't', t.data('t'))
            if (t.data('d')) imageCss += getCss(uniqueId, 'd', t.data('d'))
        });

        $(document).find('head').append('<style>' + imageCss + '</style>')

    }

    loadImg();

});